import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

const Success = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `FMCorreia`
  


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Success!" />
      <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>

    <section>
        <div className="page-formatted-text">
            <h2>Success!</h2>
            <p>Your last action was successful!</p>
        </div>
    </section>
    
    </Layout>
  )
}

export default Success

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
